import { Controller } from "stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ "form" ]

  connect() {
  }

  submitForm(event) {
      event.preventDefault()
      let form = this.formTarget
      Rails.fire(form, "submit")
  }
}
