import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "item", "tab" ]

  connect() {
  }

  toggle(event) {
      event.preventDefault()
      this.itemTargets.forEach(e => e.classList.toggle("hidden"))
  }

  switchTab(event) {
    event.preventDefault()
    this.tabTargets.forEach(e => e.classList.toggle("hidden"))
  }
  selectTab(event, prefix){
    this.tabTargets.forEach(function(e) {
      if(!e.classList.contains("hidden")){
        e.querySelectorAll('[required=required]').forEach(e => e.disabled = true)
        e.classList.toggle("hidden")
      }
    })
    let targeted = document.querySelector(this.data.get('prefix') + event.currentTarget.value)
    if(targeted != null) {
      targeted.querySelectorAll('[required=required]').forEach(e => e.disabled = false)
      targeted.classList.toggle("hidden")
    }
  }
}
