import Flatpickr from 'stimulus-flatpickr'
import { Japanese } from 'flatpickr/dist/l10n/ja.js'

import 'flatpickr/dist/themes/airbnb.css'

export default class extends Flatpickr {
  initialize() {
    this.config = {
      locale: Japanese
    }
  }
}