import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content", "button"];
  lines = 3;

  connect() {
    if (this.height() > this.expectedHeight()) {
      this.buttonTarget.classList.remove('hide');
      this.toggle();
    }
  }

  toggle() {
      if(this.contentTarget.classList.contains("truncated")){
        this.contentTarget.classList.remove('truncated');
        this.buttonTarget.innerHTML = this.data.get('less');
      } else {
        this.contentTarget.classList.add('truncated');
        this.buttonTarget.innerHTML = this.data.get('more');
      }
  }

  height() {
    return this.contentTarget.offsetHeight;
  }

  expectedHeight() {
    let style = window.getComputedStyle(this.contentTarget);
    let lineHeight = parseFloat(style.lineHeight, 10);
    return this.lines * lineHeight;
  }

}