import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "item", "view", "profile", "video", "chart" ]

  initialize() {
    this.current = this.data.get("current")
  }

  toggle(event) {
      event.preventDefault()
      this.viewTarget.classList.toggle("big-view")
      this.itemTargets.forEach(e => e.classList.toggle("hidden"))
  }
  showVideo(event) {
      event.preventDefault()
      this.viewTarget.classList.toggle("big-view")
      if(this.current == "profile") {
        this.profileTargets.forEach(e => e.classList.toggle("hidden"))
      } else {
        this.chartTargets.forEach(e => e.classList.toggle("hidden"))
      }
      this.videoTargets.forEach(e => e.classList.toggle("hidden"))
      this.current = "video"
  }
  showChart(event) {
    event.preventDefault()
    if(this.current == "video") {
      this.viewTarget.classList.toggle("big-view")
      this.videoTargets.forEach(e => e.classList.toggle("hidden"))
    } else {
      this.profileTargets.forEach(e => e.classList.toggle("hidden"))
    }
    this.chartTargets.forEach(e => e.classList.toggle("hidden"))
    this.current = "chart"
  }
  showProfile(event) {
    event.preventDefault()
    if(this.current == "video") {
      this.viewTarget.classList.toggle("big-view")
      this.videoTargets.forEach(e => e.classList.toggle("hidden"))
    } else {
      this.chartTargets.forEach(e => e.classList.toggle("hidden"))
    }
    this.profileTargets.forEach(e => e.classList.toggle("hidden"))
    this.current = "profile"
  }

  get current() {
    return this.data.get("current")
  }

  set current(value) {
    this.data.set("current", value)
  }
}
