import { Controller } from "stimulus"
import Rails from '@rails/ujs'
let tooltip = null
export default class extends Controller {
  static targets = [ "item", "button" ]
  connect() {
    tooltip = this.buttonTarget.dataset.confirm;
  }

  checkInput(event) {
    if (this.itemTargets.every((el) => el.value != '')) {
      this.buttonTarget.dataset.confirm = ''
    } else {
      this.buttonTarget.dataset.confirm = tooltip
    }
  }
}
