import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  connect() {
  }

  clear(event) {
      event.preventDefault()
      this.inputTargets.forEach((el) => el.value = null)
  }
}
