import { Controller } from "stimulus";
import * as firebase from "firebase/app";
import "firebase/auth";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "mail",
    "password",
    "error",
    "passwordConfirmation",
    "view",
    "form",
    "phone",
    "phoneCode",
    "phoneContainer",
    "phoneCodeContainer",
    "registrationContainer",
    "submitButton",
  ];

  connect() {
    var firebaseConfig = gon.firebase_config;
    firebase.initializeApp(firebaseConfig);
  }

  login() {
    let err = this.errorTargets;
    err.forEach((el) =>
      el.classList.contains("hidden") == false
        ? el.classList.add("hidden")
        : null
    );
    firebase.default
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION);
    firebase.default
      .auth()
      .signInWithEmailAndPassword(
        this.mailTarget.value,
        this.passwordTarget.value
      )
      .catch(function (error) {
        firebase.default.auth().signOut();
        var errorCode = error.code;
        if (
          errorCode == "auth/user-not-found" ||
          errorCode == "auth/wrong-password"
        ) {
          showError("mail-or-password-is-wrong", err);
        } else {
          console.log(errorCode);
          showError(errorCode, err);
        }
      })
      .then((userCredential) => {
        if (userCredential) {
          var refreshToken = userCredential.user.refreshToken;
          window.location.replace("/authenticate?token=" + refreshToken);
        }
      });
  }

  changePassword() {
    var err = this.errorTarget;
    var views = this.viewTargets;
    if (this.passwordTarget.value == this.passwordConfirmationTarget.value) {
      var pass = this.passwordTarget.value;
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          user
            .updatePassword(pass)
            .then(function () {
              views.forEach((el) => el.classList.toggle("hidden"));
            })
            .catch(function (error) {
              var errorCode = error.code;
              var errorMessage = error.message;
              err.classList.remove("hidden");
            });
        }
      });
    } else {
      err.classList.remove("hidden");
    }
  }

  submitPhoneNo() {
    setRecaptcha();
    var appVerifier = window.recaptchaVerifier;
    let codeContainer = this.phoneCodeContainerTarget;
    let phoneContainer = this.phoneContainerTarget;
    let err = this.errorTargets;
    err.forEach((el) =>
      el.classList.contains("hidden") == false
        ? el.classList.add("hidden")
        : null
    );
    firebase.default
      .auth()
      .signInWithPhoneNumber(this.phoneTarget.value, appVerifier)
      .then(function (confirmationResult) {
        window.confirmationResult = confirmationResult;
        codeContainer.classList.remove("hidden");
        phoneContainer.classList.add("hidden");
      })
      .catch(function (error) {
        showError(error.code, err);
        Rails.ajax({ url: "/log_error", type: "post", data: error });
        console.log(error);
      });
  }

  submitPhoneCode() {
    let form = this.formTarget;
    let codeContainer = this.phoneCodeContainerTarget;
    let formContainer = this.registrationContainerTarget;
    let phoneContainer = this.phoneContainerTarget;
    let confirmationResult = window.confirmationResult;
    let code = this.phoneCodeTarget;
    let err = this.errorTargets;
    err.forEach((el) =>
      el.classList.contains("hidden") == false
        ? el.classList.add("hidden")
        : null
    );
    if (code.value) {
      confirmationResult
        .confirm(code.value)
        .then(function (result) {
          let user = result.user;
          fetch(
            gon.firebase_config.hostUrl + "/check_patient?uid=" + user.uid
          ).then((data) => {
            let isNewUser = data.status == 200;
            if (isNewUser) {
              formContainer.classList.remove("hidden");
              codeContainer.classList.add("hidden");
              form.querySelector("#patient_uid").value = user.uid;
            } else {
              codeContainer.classList.add("hidden");
              phoneContainer.classList.remove("hidden");
              showError("phone-already-in-use", err);
            }
          });
        })
        .catch(function (error) {
          codeContainer.classList.add("hidden");
          phoneContainer.classList.remove("hidden");
          showError(error.code, err);
          Rails.ajax({ url: "/log_error", type: "post", data: error });
          console.log(error);
        });
    }
  }

  loginByPhone() {
    let codeContainer = this.phoneCodeContainerTarget;
    let phoneContainer = this.phoneContainerTarget;
    let confirmationResult = window.confirmationResult;
    let code = this.phoneCodeTarget;
    let err = this.errorTargets;
    err.forEach((el) =>
      el.classList.contains("hidden") == false
        ? el.classList.add("hidden")
        : null
    );
    if (code.value) {
      confirmationResult
        .confirm(code.value)
        .then(function (result) {
          let user = result.user;
          let isNewUser = result.additionalUserInfo.isNewUser;
          if (isNewUser) {
            codeContainer.classList.add("hidden");
            phoneContainer.classList.remove("hidden");
            showError("phone-not-registered", err);
          } else {
            var refreshToken = user.refreshToken;
            window.location.replace("/authenticate?token=" + refreshToken);
          }
        })
        .catch(function (error) {
          codeContainer.classList.add("hidden");
          phoneContainer.classList.remove("hidden");
          showError(error.code, err);
          console.log(error);
        });
    }
  }

  register(event) {
    event.preventDefault();
    var form = this.formTarget;
    if (
      [...form.querySelectorAll("[required]")].every(
        (el) => el.validity.valid == true
      )
    ) {
      Rails.fire(form, "submit");
    }
  }

  registerByMail(event) {
    event.preventDefault();
    var form = this.formTarget;
    let err = this.errorTargets;
    err.forEach((el) =>
      el.classList.contains("hidden") == false
        ? el.classList.add("hidden")
        : null
    );
    err.forEach((el) =>
      el.classList.contains("hidden") == false
        ? el.classList.add("hidden")
        : null
    );
    if (
      [...form.querySelectorAll("[required]")].every(
        (el) => el.validity.valid == true
      )
    ) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(
          this.mailTarget.value,
          this.passwordTarget.value
        )
        .catch(function (error) {
          showError(error.code, err);
          console.log(error);
        })
        .then((userCredential) => {
          if (userCredential) {
            var uid = userCredential.user.uid;
            form.querySelector("#doctor_uid").value = uid;
            Rails.fire(form, "submit");
          }
        });
    }
  }

  stop(event) {
    event.preventDefault();
    return false;
  }
}

function showError(errorCode, errors) {
  let error = errors.find((e) => e.id == errorCode);
  if (error) {
    error.classList.remove("hidden");
  } else {
    errors.find((e) => e.id == "other-error").classList.remove("hidden");
  }
}

function setRecaptcha() {
  if (window.recaptchaVerifier) {
    grecaptcha.reset();
  } else {
    window.recaptchaVerifier = new firebase.default.auth.RecaptchaVerifier(
      "submitPhoneButton",
      {
        size: "invisible",
        callback: function (response) {
          submitPhoneNo();
        },
      }
    );
  }
}
