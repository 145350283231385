import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "time", "datetime", "button" ]

  connect() {
  }

  toggle(event) {
      let val = event.target.value
      this.timeTargets.forEach(function(e) {
          if(e.classList.contains("active")){
            e.classList.toggle("hidden")
            e.classList.toggle("active")
          }
          if(e.id == val) {
              e.classList.toggle("hidden")
              e.classList.toggle("active")
          }
    })
    this.datetimeTarget.value = null;
    document.querySelectorAll("input[type='radio']").forEach(function(el){
      if(el.checked == true) {
        el.checked = false;
      }
    })
    if(!this.buttonTarget.classList.contains("hide")){
      this.buttonTarget.classList.toggle("hide")
    }
  }

  setTime(event) {
    let val = event.target.value
    this.datetimeTarget.value = val
    if(this.buttonTarget.classList.contains("hide")){
      this.buttonTarget.classList.toggle("hide")
    }
  }
}
