import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import questions from "./2022-12-18-questions.json";
const link = (option) => {
  let a = document.createElement("a");
  a.innerHTML = option.text;
  a.id = `option_${option.id}`;
  a.classList.add("w-full", "button-small");
  a.dataset.action = `triage#selectOption`;
  return a;
};
export default class extends Controller {
  static targets = ["container", "header", "link", "info", "loading", "wrapper", "content"];

  renderResult = async (result) => {
    let token = this.data.get('token');
    let answers = JSON.parse(this.data.get('answers')) || [];
    let ajax = await Rails.ajax({ url: "/triage", type: "post", data: JSON.stringify({answers}), headers: {'Authorization': 'Bearer ' + token} });
    console.log(ajax);
    let a = document.createElement("a");
    a.innerHTML = "最初からやり直す"
    a.classList.add("soft-link", "text-center");
    a.dataset.action = `triage#reset`;
    let questionnaireLink = document.createElement("a");
    questionnaireLink.innerHTML = "アンケートへ";
    questionnaireLink.href = "https://docs.google.com/forms/d/e/1FAIpQLSfgi7-JoKKOASuvk4CjsFHQjgF3gKHhVmHsEAhiT-0j0kyjnA/viewform?usp=pp_url"
    questionnaireLink.classList.add("button-big", "my-4");
    this.clearContainer();
    this.headerTarget.textContent = result;
    if(result == "link_to_hospital_pregnancy_medicine"){
      this.headerTarget.textContent = "";
      let newHeader = document.createElement("a");
      newHeader.innerHTML = "こちらをご参照ください"
      newHeader.classList.add("text-center","heading-comment", "underline", "pb-5","-mt-5", "hover:text-main")
      newHeader.href = "https://www.ncchd.go.jp/kusuri/process/index.html"
      newHeader.dataset.target="triage.link";
      this.headerTarget.after(newHeader);
    } else {
      this.headerTarget.textContent = result;
    }
    this.containerTarget.appendChild(questionnaireLink);
    this.containerTarget.appendChild(a);
  };

  reset() {
    if(this.infoTarget.classList.contains("hidden")){
      this.infoTarget.classList.toggle("hidden");
    }
    if(!this.contentTarget.classList.contains("hidden")){
      this.contentTarget.classList.toggle("hidden");
    }
    this.renderQuestion(questions.questions.question);
    this.data.set('answers', JSON.stringify([]) )
  }

  consent() {
    if(!this.infoTarget.classList.contains("hidden")){
      this.infoTarget.classList.toggle("hidden");
    }
    if(this.contentTarget.classList.contains("hidden")){
      this.contentTarget.classList.toggle("hidden");
    }
  }

  renderQuestion = (question) => {
    this.clearContainer();
    this.headerTarget.textContent = question.text;
    let options = question.options;
    options.forEach((o) => this.containerTarget.appendChild(link(o)));
  };

  clearContainer() {
    let div = this.containerTarget;
    while(div.firstChild && div.removeChild(div.firstChild));

    if(this.hasLinkTarget){
      this.linkTarget.remove();
    }
    if(!this.loadingTarget.classList.contains("hidden")){
      this.loadingTarget.classList.toggle("hidden");
    }
  }

  async selectOption(event) {
    if(!this.infoTarget.classList.contains("hidden")){
      this.infoTarget.classList.toggle("hidden");
    }
    let id = event.currentTarget.id.split("_")[1];
    let splittedId = id.split("-");
    let firstQuestion = questions.questions.question;
    let currentOption = null;
    for (let i = 1; i < splittedId.length; i++) {
      currentOption = currentOption
        ? currentOption.question.options[parseInt(splittedId[i]) - 1]
        : firstQuestion.options[parseInt(splittedId[i]) - 1];
    }
    let answers = JSON.parse(this.data.get('answers')) || [];
    answers.push({question: this.headerTarget.innerHTML, id: id, answer: currentOption.text})
    this.data.set('answers', JSON.stringify(answers) )
    this.wrapperTarget.classList.toggle("hidden");
    this.loadingTarget.classList.toggle("hidden");
    await new Promise(r => setTimeout(r, Math.floor(Math.random() * (2000 - 1000 + 1) + 1000)));
  
    this.loadingTarget.classList.toggle("hidden");
    this.wrapperTarget.classList.toggle("hidden");
    if (currentOption.question) {
      this.renderQuestion(currentOption.question);
    } else {
      this.renderResult(currentOption.result);
    }
  }

  connect() {
    this.reset();

  }
}
