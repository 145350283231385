import { Controller } from "stimulus";
import "agora-rtc-sdk-ng";

var rtc = {
  client: null,
  joined: false,
  published: false,
  localStream: null,
  localAudioTrack: null,
  localVideoTrack: null,
  remoteStreams: [],
  params: {},
};

var AgoraRTC = global.AgoraRTC;

async function startCall(options, view) {
  rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

  rtc.client.on("user-published", async (user, mediaType) => {
    await rtc.client.subscribe(user, mediaType);
    console.log("subscribe success");
    if (mediaType === "video") {
      const remoteVideoTrack = user.videoTrack;
      if (document.getElementById("remote_video_" + user.uid) != null) {
        removeView(user.uid);
      }
      addView(user.uid.toString(), view);
      remoteVideoTrack.play("remote_video_" + user.uid);
    }

    if (mediaType === "audio") {
      const remoteAudioTrack = user.audioTrack;
      remoteAudioTrack.play();
    }

    rtc.client.on("user-unpublished", (user) => {
      removeView(user.uid);
    });
  });
  await rtc.client.join(
    options.appID,
    options.channel,
    options.token,
    options.uid
  );
  rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
  rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
  await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
  rtc.localVideoTrack.play("local_stream");
  console.log("publish success!");
}

function addView(uid, target) {
  var newDiv = document.createElement("div");
  newDiv.setAttribute("id", "remote_video_" + uid);
  newDiv.setAttribute("class", "flex-1 h-full");
  target.appendChild(newDiv);
}

function removeView(uid) {
  var div = document.getElementById("remote_video_" + uid);
  div.remove();
}
export default class extends Controller {
  static targets = ["view", "muteButton", "muteIcon"];

  connect() {
    let view = this.viewTarget;
    let id = this.data.get("id");
    let appId = gon.app_id;
    let token = gon.token;
    let uid = gon.uid;
    var options = {
      appID: appId,
      channel: id,
      uid: uid,
      token: token,
    };
    if (gon.app_id == null) {
      location.reload();
    }
    startCall(options, view);
  }

  disconnect() {
    rtc.client.leave(
      function () {
        rtc.localAudioTrack.close();
        rtc.localVideoTrack.close();

        rtc.client.remoteUsers.forEach((user) => {
          removeView(user.uid);
        });
        console.log("client leaves channel success");
      },
      function (err) {
        console.log("channel leave failed");
        console.error(err);
      }
    );
  }

  toggleMute(event) {
    event.preventDefault();
    if (this.muteButtonTarget.classList.contains("call-unmuted")) {
      this.muteButtonTarget.classList.replace("call-unmuted", "call-muted");
      rtc.localAudioTrack.setMuted(true);
    } else {
      this.muteButtonTarget.classList.replace("call-muted", "call-unmuted");
      rtc.localAudioTrack.setMuted(false);
    }
    this.muteIconTargets.forEach((e) => e.classList.toggle("hidden"));
  }
}
