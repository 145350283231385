import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]

  connect() {
    let date = new Date(this.data.get("date"))
    let tenBeforeAppointment = date.setMinutes(date.getMinutes() - 10)
    if (tenBeforeAppointment >= Date.now()){
        window.setTimeout(()=>{window.location.reload();}, tenBeforeAppointment - Date.now())
    }
  }
}
